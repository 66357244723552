.selectWallet {
  background-image: url("../img/signup-bg-old.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  mix-blend-mode: hard-light;
  padding-top: 150px;
  padding-bottom: 100px;
}

.signup-heading-wrapper p {
  font-size: 13px !important;
}
.signup-heading-wrapper {
  padding-bottom: 10px;
}
.signup-heading-wrapper h2 {
  font-family: "Source Sans Pro" !important;
  font-size: 45px !important;
}
.selectWallet .wallets-list-wrapper ul.wallets-list {
  padding: 0px;
  list-style: none;
}
.selectWallet .wallets-list-wrapper ul.wallets-list .wallet-card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* background-image: url("../img/select-bg.png"); */
  background-color: #9999998a;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(109px);
  backdrop-filter: blur(109px);
}
.wallet-card-wrapper .wallet-img-wrapper {
  flex: 0 0 40%;
  text-align: center;
}
.wallet-card-wrapper .wallet-content-wrapper {
  flex: 0 0 60%;
}
.wallet-card-wrapper .wallet-content-wrapper h3 {
  margin: 0px;
}
.selectWallet .wallets-list-wrapper ul.wallets-list label.wallet-container {
  width: 100%;
  position: relative;
}
.selectWallet
  .wallets-list-wrapper
  ul.wallets-list
  label.wallet-container
  input[type="radio"] {
  position: absolute;
  right: 900px;
}
.selectWallet
  .wallets-list-wrapper
  ul.wallets-list
  label.wallet-container
  input[type="radio"]:checked
  ~ .wallet-card-wrapper {
  border: 1px solid #fff;
  position: relative;
}
.wallets-list-wrapper ul.wallets-list li.wallet {
  margin: 20px 0px;
}
.selectWallet
  .wallets-list-wrapper
  ul.wallets-list
  label.wallet-container
  input[type="radio"]:checked
  ~ .wallet-card-wrapper::after {
  content: url("../img/right-slide-outline.png");
  position: absolute;
  right: -4px;
  top: -3px;
}
.selectWallet
  .wallets-list-wrapper
  ul.wallets-list
  label.wallet-container
  input[type="radio"]:checked
  ~ .wallet-card-wrapper::before {
  content: url("../img/slide-outline.png");
  position: absolute;
  left: -4px;
  bottom: -10px;
}
.selectWallet
  .wallets-list-wrapper
  ul.wallets-list
  label.wallet-container
  input[type="radio"]:checked
  ~ .wallet-card-wrapper
  .wallet-content-wrapper {
  opacity: 1;
}
.selectWallet li.wallet img {
  height: 70px;
  width: 70px;
  object-fit: contain;
}

.wallets-list-wrapper .btn {
  padding: 0px !important;
  height: 50px;
  font-size: 14px;
  background-image: linear-gradient(
    to right,
    #00cfc4,
    #00cec4,
    #00cec4,
    #00cdc4,
    #00ccc4,
    #00c8c7,
    #00c3c9,
    #00bfcb,
    #00b5cd,
    #00abce,
    #00a0cc,
    #0895c9
  );
  font-weight: 600;
}
.wallet-card-wrapper .wallet-content-wrapper {
  opacity: 64%;
}
.wallet-card-wrapper .wallet-img-wrapper img {
  mix-blend-mode: color-dodge;
}
