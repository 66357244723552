.onboarding-sec {
	padding-top: 150px;
	background-image: url("../img/signup-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	padding-bottom: 150px;
}
.onboarding-wrapper input[type="file"]::file-selector-button {
	background: #283748;
	position: absolute;
	right: 1%;
	font-family: "Poppins";
	color: #fff;
	font-size: 12px;
	height: 34px;
	padding: 5px 20px;
	border-radius: 7px;
	border: 1px solid #2f3d4e;
	top: 18%;
}
.onboarding-heading-wrapper h2 {
	font-family: "Source Sans Pro" !important;
	text-transform: capitalize;
}
.onboarding-sec .onboarding-wrapper {
	padding: 50px 20px;
	border: 2px solid #068c8c;
	border-radius: 5px;
	box-shadow: inset 1px 3px 17px 3px #ffffff30, 1px 3px 17px 3px #ffffff30;
}
.onboarding-wrapper .form-label-group input {
	margin: 0px 0px;
}
.onboarding-wrapper .importance-wrapper {
	padding: 20px;
	border: 1px solid;
	margin-bottom: 30px;
	box-shadow: 1px 3px 17px 3px #ffffff30;
	position: relative;
}
.onboarding-wrapper .importance-wrapper::before {
	content: url("../img/right-slide-outline.png");
	position: absolute;
	top: -2px;
	right: -3px;
}
.onboarding-wrapper .importance-wrapper::after {
	content: url("../img/slide-outline.png");
	position: absolute;
	bottom: -9px;
	left: -2px;
}
.onboarding-wrapper .importance-wrapper h4 {
	font-family: "Montserrat";
	font-size: 25px !important;
	font-weight: 500 !important;
}
.onboarding-wrapper .importance-wrapper p {
	font-size: 13px !important;
	color: #b3b6b8;
	line-height: 1.5;
}
.onboarding-wrapper .provide-wrapper h4 {
	font-size: 22px !important;
	font-weight: 500 !important;
}
.onboarding-wrapper .provide-wrapper {
	padding-bottom: 31px;
	padding-top: 22px;
}
.upload-heading-wrapper h4 {
	font-family: "Montserrat";
	font-size: 24px !important;
	font-weight: 500 !important;
}
.upload-heading-wrapper {
	padding-bottom: 30px;
}
.upload-heading-wrapper ul {
	padding-left: 30px;
}
.upload-heading-wrapper ul li {
	line-height: 2;
	font-size: 13px !important;
	color: #b3b6b8;
}
.check-wrapper h4 {
	font-family: "Montserrat";
	font-size: 24px !important;
	font-weight: 500 !important;
	line-height: 1.5;
}
.check-wrapper {
	padding-bottom: 30px;
}
.onboarding-wrapper .form-label-group input[type="text"] {
	background-image: url("../img/Icon\ metro-pencil.png");
	background-repeat: no-repeat;
	background-position: 97% center;
}
.onboarding-wrapper .form-check label.form-check-label {
	font-size: 14px;
	color: #a0a0a0;
}
.onboarding-wrapper .form-check input#exampleCheck1 {
	margin-top: -13px;
	margin-left: -21px;
}
.onboarding-wrapper button.btn {
	padding: 10px 37px !important;
	font-size: 15px !important;
	height: 47px;
	margin: 0px;
}
@media (max-width: 480px) {
	.check-wrapper h4 {
		font-size: 16px !important;
	}
	.onboarding-heading-wrapper h2 {
		font-size: 28px !important;
	}
}
