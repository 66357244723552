.signup {
  background-image: url("../img/signup-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  /* mix-blend-mode: hard-light; */
  padding-top: 150px;
  padding-bottom: 100px;
}
.signup-wrapper input {
  background: #09090a;
  color: #fff;
  border: 2px solid #ffffff;
  height: 54px;
  margin: 23px 0px;
}
.signup-wrapper input:focus {
  background: #09090a !important;
  color: #fff;
  border: 2px solid #ffffff;
}
.signup-wrapper .form-label-group label {
  color: #ffffff;
}
.signup-wrapper .form-label-group.in-border label::after {
  background: #09090a !important;
  height: 25px !important;
  top: 10px !important;
}
.signup-wrapper .form-label-group.in-border input:focus ~ label {
  font-size: 18px;
}
.signup-wrapper .form-label-group.in-border .input-group-btn {
  position: absolute;
  top: 18%;
  right: 3%;
}
.signup-wrapper .form-label-group.in-border {
  position: relative;
}
.signup-wrapper .form-label-group.in-border .input-group-btn button {
  background: transparent;
  color: #a29cb9;
  border: none;
}
.signup-wrapper button.btn {
  background-image: linear-gradient(
    to right,
    #00cfc4,
    #00c9c7,
    #00c3c8,
    #00bdc9,
    #00b7c9,
    #00aecf,
    #00a4d3,
    #009ad4,
    #0088db,
    #0075de,
    #005edc,
    #1641d2
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.signup-wrapper .btn {
  padding: 0px !important;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
}
.signup-wrapper .info-wrapper {
  text-align: center;
  font-family: "Montserrat";
}
.signup-wrapper .info-wrapper a {
  color: #02bac5;
}
.signup-heading-wrapper p {
  font-size: 13px !important;
}
.signup-heading-wrapper {
  padding-bottom: 10px;
}
.signup-heading-wrapper h2 {
  font-family: "Source Sans Pro" !important;
  font-size: 45px !important;
}
.form-label-group.in-border input ~ label {
  left: var(--padding-y) !important;
  top: 5px;
  height: auto;
  font-size: 18px;
  background: #09090a;
  padding: 1px 7px;
  border-radius: 0px;
  transform: translateY(-50%) scale(0.7) !important;
}
.form-label-group.in-border input:focus ~ label {
  left: var(--padding-y) !important;
}
.form-label-group input[type="text"]::placeholder {
  color: #9c9c9d;
  visibility: visible;
  opacity: 1;
  font-size: 11px;
}

.form-label-group input[type="password"]::placeholder {
  color: #9c9c9d;
  visibility: visible;
  opacity: 1;
  font-size: 11px;
}
